import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function render(){var _vm=this,_c=_vm._self._c;return (!!_vm.trait && !!_vm.traitDescription)?_c(VMenu,{attrs:{"close-on-content-click":false,"offset-x":"","nudge-left":250},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VChip,_vm._g(_vm._b({staticClass:"px-1 text-uppercase roboto-condensed font-weight-medium tag",class:_vm.custom_class,staticStyle:{"border-color":"#f5daab !important"},attrs:{"label":"","color":"white--text"}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(_vm.trait)+" ")])]}}],null,false,2971163420)},[_c(VCard,{attrs:{"elevation":"5","width":"500"}},[_c(VCardTitle,[_vm._v(_vm._s(_vm.capitalizedTrait))]),_c(VCardText,[_vm._v(_vm._s(_vm.traitDescription))])],1)],1):_c(VChip,{staticClass:"px-1 text-uppercase roboto-condensed font-weight-medium tag",class:!!_vm.border_less ? `tag_small ${_vm.custom_class}` : `${_vm.custom_class}`,style:(!!_vm.border_less ? '' : 'border-color: #f5daab !important;'),attrs:{"label":"","color":"white--text"}},[_c('span',{class:!!_vm.icon ? 'gi-icon white' : '',style:(!!_vm.icon ? _vm.GameIcons.get.style(_vm.icon) : '')},[_vm._v(_vm._s(!!_vm.icon ? _vm.icon : _vm.trait))])])
}
var staticRenderFns = []

export { render, staticRenderFns }