import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"persistent":"","fullscreen":_vm.XSorSMOnly,"content-class":"no-border-radius","no-click-animation":"","max-width":"600"},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.agree.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.disagree.apply(null, arguments)}]},model:{value:(_vm.shown),callback:function ($$v) {_vm.shown=$$v},expression:"shown"}},[_c(VCard,{staticClass:"py-4",attrs:{"tile":true}},[_c(VCardTitle,{staticClass:"text-h6 py-0"},[_c('span',{staticClass:"noselect"},[_vm._v(_vm._s(_vm.title))])]),_c(VCardText,{staticClass:"py-1 text-body-2"},[_vm._v(_vm._s(_vm.text))]),_c(VCardActions,{staticClass:"px-6 d-flex"},[_c(VBtn,{staticClass:"px-4 d-flex flex-grow-1",attrs:{"outlined":"","tile":""},on:{"click":_vm.agree}},[_vm._v(" "+_vm._s(!!_vm.agreeText ? _vm.agreeText : 'Yes')+" ")]),_c(VBtn,{staticClass:"px-4 d-flex flex-grow-1",attrs:{"outlined":"","tile":""},on:{"click":_vm.disagree}},[_vm._v(" "+_vm._s(!!_vm.disagreeText ? _vm.disagreeText : 'No')+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }